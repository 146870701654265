@import "../../../../assets/css/global.scss";
@import "../../../../assets/css/components.scss";

.formGrid {
    @include formGrid;
}

.bill_table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;

    .bill_title {
        background-color: $bg_title_blue !important;
        color: $bg_white !important;
        font-weight: bold !important;
    }

    th,
    td {
        text-align: left;
        padding: 8px;
    }

    tr {
        &:nth-child(even) {
            background-color: $bg_white;
        }

        &:nth-child(odd) {
            background-color: $fill_bg_twel;
        }
    }
}