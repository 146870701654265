.table_dot_dropdown{
    cursor: pointer;
    .dot_icon{
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #e2e7ee;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            color: black;
            font-size: 18px;
        }
    }

    
}

.menu_items{
        list-style-type: none;
        margin-left: -40px;

        a{
            text-decoration: none;
            color: black;

            li{
            padding: 5px 8px;
            font-size: 12px;
            color: black;
            font-weight: 400;
            border-radius: 5px;
            margin: 0px 5px;
        }
        }
        

        a:hover{
            color: white;

            li{
                color: white;
                background-color: #005ca8;
            }
        }
    }