@import "../../assets/css/global.scss";
@import "../../assets/css/components.scss";

.boxStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    background-color: #eee;
    box-shadow: 24;
    color: black !important;    
    height: 100%;
    overflow: auto;
    padding: 10px;
    color: black !important;
}
.boxStyle p{
    color:black !important;
}