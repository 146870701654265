@import "../../assets/css/global.scss";

.heading{
    font-size: $font18;
    color: $text_col_two;
    font-weight: $font_weight_700;
    margin-top: 5px;
}
@media only screen and (max-width: 768px){
    div span .heading {
        margin-left: 13px;
        padding-top: 15px;
    }
}