@import "../../../assets/css/global.scss";

.download_button,
.download_multiple_button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.download_button button {
    background-color: #6aba2a;
    border: solid 1px $fill_border;
    padding: 8px 25px;
    border-radius: 5px;
    cursor: pointer !important;
    font-size: $font11;
    color: $bg_white;
    font-weight: $font_weight_600;
}

.download_multiple_button button {
    background-color: #005ca8;
    border: solid 1px $fill_border;
    padding: 2px;
    border-radius: 5px;
    cursor: pointer !important;
    font-size: $font10;
    color: $bg_white;
    font-weight: $font_weight_600;
}