@import "../../../assets/css/global.scss";
@import "../../../assets/css/components.scss";

.invoice_conatiner {
    font-size: $font13;
    color: $text_col_four !important;
}

.download_customize_plan_invoice{
    display: flex;
    justify-content: center;
    margin: 20px 0px;
    button{
        background-color: #005ca8;
        border: none;
        color: white;
        padding: 8px 20px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
    }
}