@import "../../assets/css/global.scss";

.table_list{
    border: 1px solid $fill_border_two;
    border-radius: 5px;
    margin-bottom: 6px;
    // background-color: brown;
    .list_head{
        color: $fill_bg_grey;
        // color: white;
        margin: 0px;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: $font13;
        svg{
            color: $bg_title_blue;
            font-size: $font18;
        }

        span {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .right_texts{
                font-size: 12px;
                // background-color: aqua;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
            }
        }
    }
}

.list_section{
    background-color: $fill_bg_five;
}

.list_items{
    background-color: $fill_bg_five;
}

.list_items a{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 15px;
    span{
        font-size: $font13;
    }
    
}
.list_items p{
    margin: 0;
    font-size: $font13;
    font-weight: 500;
    color: $fill_bg_grey;
}
.second_text{
    font-size: 14px;
    font-weight: 600;
    color: $fill_bg_grey;
}