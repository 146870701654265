.view_question_container{
    width: 70vw;
    height: auto;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;

    .view_question_header{
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #bbc5d5;

        p{
            margin: 0;
            color: black;
            font-weight: 500;
        }

        svg{
            color: black;
            cursor: pointer;
        }
    }

    .view_question_content{
        padding: 10px;
        width: 100%;

        .view_field{
            // border: 1px solid #bbc5d5;
            border: 1px solid black;
            border-radius: 5px;
            width: 100%;
            height: 79vh;
            background-color: lightblue;
        }
    }

    .question_download{
        display: flex;
        padding: 0px 10px 10px;
        display: flex;
        justify-content: flex-end;

        button{
            padding: 5px 20px;

            svg{
                color: white;
                margin: 0px 3px 0px -5px;
                font-size: 20px;
            }
        }

        button:hover{
            background-color: #67ace4a0;
        }
    }
}