@import "../../../assets/css/global.scss";
@import "../../../assets/css/components.scss";

@mixin shadow {
    box-shadow: 0 12px 14px $bg_light_gray, 0 13px 13px $bg_white;
}

@mixin flex_container {
    display: flex;
    flex-direction: row;
}


.logo_body {
    width: 100%;

    td {
        &:first-child {
            img {
                width: 30%;
            }

            text-align: left
        }

        &:last-child {
            text-align: right
        }
    }
}

.invoice_body {
    @include shadow;
    padding: 20px;
    width: 80% !important;

    .biller_details_div {
        @include flex_container;
        margin-top: 20px;

        div {
            &:first-child {
                width: 70%;
            }

            &:last-child {
                width: 30%;
            }
        }

        .biller_address {
            color: $black !important;

            .biller_address_details {
                margin-top: 20px;
                width: 100%;
            }
        }
    }

    .heading {
        color: $bg_title_blue;
        text-align: center
    }

    .biller_details_table {
        border-collapse: collapse;

        tr,
        td {
            padding: 0px;
            margin: 0px;
            padding: 0px 20px 0px 0px;
            vertical-align: top;
            text-align: left;

            * {
                vertical-align: top;
                text-align: left;
            }
        }

        th {
            vertical-align: top;
            text-align: left;

            * {
                vertical-align: top;
                text-align: left;
            }
        }
    }



    .bill_table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 20px;

        .bill_title {
            background-color: $bg_title_blue !important;
            color: $bg_white !important;
            font-weight: bold !important;
        }

        th,
        td {
            text-align: left;
            padding: 8px;
        }

        tr {
            &:nth-child(even) {
                background-color: $bg_white;
            }

            &:nth-child(odd) {
                background-color: $bg_light_gray;
            }
        }
    }

}

.customize_bill_table {
    border-collapse: collapse;
    width: 100%;


    th,
    td {
        text-align: left;
        vertical-align: top;
        padding-top: 10px;
        border-bottom: 1px solid $bg_light_gray;
    }
}

.term_condition {
    color: $black !important;
    width: 100%;
    h3 {
        font-weight: normal;
    }
}

.font_bold {
    font-weight: bold;
}

.payment_status {
    color: $bg_green;
}