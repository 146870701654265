@import "../../../assets/css/global.scss";

.left_bar {
    height: 100vh;
    background-color: $bg_white;
    min-width: 60px;
    max-width: 60px;
    border-right: solid 1px $fill_border;
    float: left;
    transition: min-width 0.08s ease-in;
    position: relative;
    z-index: 999;
    .extend_btn {
        border-bottom: 2px solid #dee9f2;
        position: absolute;
        top: 36px;
        left: 14px;
        transition: left 0.1s ease-in-out;

        svg {
            color: #005ca8;
            background-color: #dee9f2;
            border-radius: 50%;
            padding: 2px;
            font-size: 32px;
            transform: rotate(-90deg);
            cursor: pointer;
        }

        &:hover .tooltip {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.1s ease-in;
        }

        .tooltip {
            visibility: hidden;
            width: 120px;
            background-color: #005ca8;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 1;
            top: 0px;
            left: 350%;
            margin-left: -60px;
            opacity: 0;
            transition: opacity 0.3s;
            font-size: 12px;
            font-weight: 600;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 100%;
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent #005ca8 transparent transparent;
            }
        }
    }
}

//Css For Menu Expand
.left_bar.extend {
    min-width: 220px;
    transition: min-width 0.08s ease-in;
    max-width: 60px;

    li {
        display: flex;
        gap: 12px;
        align-items: center;
        // margin-bottom: 26px;

        div {
            &:hover {
                color: #005ca8;
            }
        }
    }

    .extend_btn {
        position: absolute;
        left: 180px;
        // background-color: #d9dee2;
        transition: left 0.1s ease-in-out;

        svg {
            transform: rotate(90deg);
        }

        .tooltip {
            visibility: hidden;
        }

        &:hover .tooltip {
            visibility: hidden;
            opacity: 0;
        }
    }
}

svg {
    color: $fill_border_two;
}

.menu_list {
    margin-top: 90px;
}

.icons_list {
    list-style-type: none;
}

.icons_list li {
    margin-left: -22px;
    margin-bottom: 20px;
    padding-top: 5px;
    position: relative;

    p {
        position: absolute;
        display: inline;
        z-index: 1;
        background-color: #005ca8;
        color: white;
        // left: 48px;
        left: 45px;
        top: -6.5px;
        border-radius: 4px;
        font-size: 0px;
        font-weight: 0;
        padding: 0px;
    }

    .triangle {
        position: absolute;
        top: 14px;
        left: 43px;
        width: 0;
        height: 0;
    }
}

.icons_list li:hover {
    border-right: 3px solid $bg_green;
    cursor: pointer;

    p {
        transition: 200ms;
        font-size: 12px;
        font-weight: 600;
        padding: 5px 10px;
        width: 140px;
    }

    .triangle {
        transition: 100ms;
        border-top: 5px solid transparent;
        border-right: 5px solid #005ca8;
        border-bottom: 5px solid transparent;
    }

    svg {
        color: $bg_title_blue;
        transition: 200ms;
        cursor: pointer;
    }
}

.log_out {
    margin-top: 70px;

    ul {
        list-style-type: none;

        li {
            margin-left: -22px;

            svg {
                font-size: 25px;
                cursor: pointer;
            }
        }

        li:hover {
            svg {
                color: red;
            }
        }
    }
}

.account {
    display: none;
}

@media only screen and (max-width: 820px) {
    .account {
        display: block;
    }

    .icons_list li {
        p {
            display: none;
        }

        .triangle {
            display: none;
        }
    }
}

@media only screen and (max-width: 768px) {
    .left_bar {
        display: none;
    }
}