._loading_overlay_wrapper {
  position: inherit !important;
}

._loading_overlay_overlay {
  z-index: 9999 !important;
  position: fixed !important;
}

::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
  background-color: transparent;
  border-radius: 7px;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #BCC3CB;
}

html {
  scroll-behavior: smooth;
}
/* .css-qivjh0-MuiStepLabel-label.MuiStepLabel-alternativeLabel{
  margin-top: 5px !important;
  font-size: 5px !important;
} */


.MuiStepLabel-alternativeLabel {
  margin-top: 0px !important;
  font-size: 10px !important;
}
