.content{
    background-color: white;
    height: auto;
    width: 360px;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    box-shadow: 1px 1px 10px black;
    .header{
        width: 100%;
        background-color: rgb(222, 222, 222);
        padding: 15px 20px;
        box-sizing: border-box;
        p{
            margin: 0;
            color: black;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
            font-size: 14px;
        }
        p span:nth-child(2){
            cursor: pointer;
            svg{
                color: rgb(100, 100, 100);
                font-size: 30px;
                border: 2px solid rgb(100, 100, 100);
                border-radius: 10px;
            }
        }
    }
    .form{
        // background-color: aquamarine;
        width: 100%;
        height: auto;
        padding: 0px 20px 20px 20px;
        .form_content{
            background-color: white;
            width: 100%;
            .first_content{
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                .first_content_left, .first_content_right{
                    width: 47%;
                    label{
                        font-weight: 600;
                    }
                }
                .first_content_left{
                    input[type=text]{
                        font-size: 12px;
                        border: 1px solid grey;
                    }
                    .select_field{
                        margin-top: 10px;
                        label{
                            font-size: 12px;
                            border: none;
                            padding: 0;
                        }
                        select{
                            font-size: 12px;
                        }
                    }
                }
                .first_content_right{
                    input[type=email]{
                        font-size: 12px;
                        border: 1px solid grey;
                    }
                    input[type=text]{
                        font-size: 12px;
                        border: 1px solid grey;
                    }
                    .select_field{
                        margin-top: 10px;
                        label{
                            font-size: 12px;
                            border: none;
                            padding: 0;
                        }
                        select{
                            font-size: 12px;
                        }
                    }
                }
            }
            .second_content{
                margin-bottom: 30px;
                p{
                    margin: 0;
                    color: black;
                    font-weight: 500;
                    font-size: 15px;
                }
                .select_field{
                    label{
                        font-size: 12px;
                        border: none;
                        padding: 0;
                        font-weight: 600;
                    }
                    select{
                        font-size: 12px;
                    }
                }
                .second_sub_content{
                    display: flex;
                    justify-content: space-between;
                    .second_sub_content_left, .second_sub_content_right{
                        width: 47%;
                        label{
                            font-size: 12px;
                            font-weight: 600;
                            border: none;
                            padding: 0;
                        }
                        select{
                            font-size: 12px;
                        }
                    }
                }
                // position: relative;
                // label{
                //     font-weight: 600;
                //     border: none;
                //     padding: 0;
                // }
                // input[type=password]{
                //     font-size: 12px;
                //     border: 1px solid grey;
                // }
                // svg{
                //     position: absolute;
                //     color: rgb(98, 98, 98);
                //     font-size: 20px;
                //     top: 33px;
                //     right: 10px;
                // }
                .calculation{
                    margin-top: 10px;
                    p{
                        font-size: 14px;
                        color: gray;
                        margin: 0;
                        span{
                            color: green;
                        }
                    }
                }
            }
        }
    }
}

meter{
    margin: 0.5em 0;
    width: 100%;
}