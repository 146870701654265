// SUBSCRIPTION 
@import "../../assets/css/global.scss";

.subscription{
    border-radius: 10px;
    background-color: $bg_white;
    padding: 8px;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.subscription .subscription_left h6{
    margin: 0;
    color: $text_col_two;
    font-size: $font11;
}
.subscription .subscription_left p{
    margin: 5px 0px 0px 0px;
    font-size: 9px;
    color: $text_col_two;
}

.subscription .subscription_right{
    button{
        border: none;
        background-color: #e69875;
        font-size: 11px;
        padding: 4px 8px;
        color: white;
        border-radius: 4px;
        cursor: pointer;
    }
}
