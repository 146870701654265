@import "../../../../assets/css/global.scss";

.profileSection {
    height: 90vh;
}

.profile {
    display: flex;
    justify-content: space-between;
}

.text_hello {
    font-size: $font18;
    color: $text_col_two;
}

.name {
    font-size: $font20;
    font-weight: bold;
    color: $text_col_two;
    text-transform: capitalize;
}

.logoutDiv {
    position: absolute;
    bottom: 0px;
    border-top: 1px solid #ddd;
    width: 100%;

    .logout {
        font-size: $font12;
        font-weight: bold;
        color: #005ca8;
        margin-top: 0px;
        cursor: pointer;
        text-decoration: none;

    }
}


.quote {
    font-size: $font10;
    color: $text_col;
    margin-top: 0px;
}

// .profile{
//         img{
//             width: 80px;
//         }
// } 
.profile {
    .profile_upload {
        // display: inline-block;
        min-width: 80px;
        max-width: 80px;
        min-height: 80px;
        max-height: 80px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #005ca8;
        position: relative;

        img {
            width: 80px;
        }

        input[type=file] {
            display: none;
        }

        label {
            display: inline-block;
            width: 80px !important;
            height: 40px !important;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            // opacity: 0.2;
            position: absolute;
            bottom: 0px;
            border-radius: 0px 0px 39px 39px;
            cursor: pointer;

            svg {
                color: white;
            }
        }
    }
}


.edit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    color: $text_col_two;
    font-weight: bold;
    margin-top: -5px;
    cursor: pointer;
}

.edit svg {
    color: $bg_title_blue;
    width: 16px;
}

.link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 15px;
}

.url {
    font-size: $font10;
    color: $fill_bg_grey;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $fill_border;
    background-color: $fill_bg_one;
    border-radius: 25px;
    padding: 7px 13px;
}

.url span {
    color: $fill_border_two;
}

.link svg {
    color: $fill_border_two;
    width: 18px;
}

.invite {
    font-size: $font10;
    font-weight: 800;
    color: $text_col_three;
    background-color: $fill_border;
    border: 1px solid $fill_border;
    padding: 11px;
    border-radius: 25px;
}

// Hide Icon
@media only screen and (max-width: 820px) {
    .link {
        display: flex;
        justify-content: flex-start;
    }

    .invite {
        margin-left: 20px;
    }
}

@media only screen and (max-width: 680px) {
    .link {
        display: flex;
        justify-content: space-between;
    }

    .profileSection {
        height: 80vh;
    }
}