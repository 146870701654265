@import "../../assets/css/global.scss";

.container{
    background-color: white;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid rgba(158, 158, 158, 0.219);
    max-width: 1000px;
    .select_container{
        background-color: #F7F8FA;
        padding: 24px;
        border-radius: 12px;

        label{
            font-size: 16px;
        }
        .miui_grid{
            margin-top: 4px;
            padding-left: 0;
            margin-left: 16px;
        }
        .select_options_amount{
            display: flex;
            font-weight: 600;
            font-size: 24px;

            .amount{
                width:100px
            }
        }
    }

    .description{
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px 0px;
        .head{
            font-weight: 600;
        }
        .list{
            ul{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                list-style-type: square;
                list-style-position: outside;
                padding: 0px 24px ;
                grid-gap: 16px;

                @media only screen and (max-width: 768px) {
                    grid-template-columns: repeat(1, 1fr);
                }
                li{
                    width: 90%;
                }
            }
        }
    }
    .btns{
        gap: 16px;
        width: 100%;
        margin-left: auto;
        justify-content: end;
    }
    .btns, .quantity{
        display: flex;
        align-items: center;
       
    }
    .quantity{
        font-weight: 600px;
    }
}