@import "../../assets/css/global.scss";
@import "../../assets/css/components.scss";


@mixin btn_design($btn_color, $text_color, $width, $padding, $fontSize) {
    border: 1px solid transparent;
    background-color: $btn_color;
    padding: $padding;
    font-size: $fontSize;
    font-weight: bold;
    color: $text_color;
    width: $width;
    cursor: pointer;
    border-radius: 3px;
}

.btn_apply_blue {
    margin: 2px;
    margin-top: 3em;
    font-size: 10px;
    @include btn_design($bg_title_blue, $bg_white, 100%, $paddingBtn, $fontBtnWeb)
}

.btn_address {
    margin: 2px;
    @include btn_design($bg_light_blue, $bg_title_blue, 100px, $paddingBtn, $fontBtnWeb)
}

.btn_green {
    @include btn_design($bg_green, $bg_white, 150px, $paddingBtn, $fontBtnWeb)
}

.btn_gray {
    @include btn_design($secondaryLight, black, 150px, $paddingBtn, $fontBtnWeb)
}

.btn_light_green {
    @include btn_design($bg_light_green, $bg_green, 150px, $paddingBtn, $fontBtnWeb)
}

.btn_gray_empty {
    border: 1px solid darkgray;
    background-color: transparent;
    padding: $paddingBtn;
    font-size: $fontBtnWeb;
    font-weight: bold;
    color: black;
    width: 150px;
    cursor: pointer;
    border-radius: 3px;
}

.btn_light_gray {
    @include btn_design($bg_light_gray, $black, 150px, $paddingBtn, $fontBtnWeb)
}

.btn_light_blue {
    @include btn_design($bg_light_blue, $bg_title_blue, 150px, $paddingBtn, $fontBtnWeb);
    border-radius: 5px;
}

.fullwidth {
    width: 100%;
}

@media only screen and (max-width: 680px) {
    .btn_green {
        @include btn_design($bg_green, $bg_white, 100%, $paddingBtnMob, $fontBtnMobile)
    }

    .btn_light_green {
        @include btn_design($bg_light_green, $bg_green, 100%, $paddingBtnMob, $fontBtnMobile)
    }

    .btn_light_gray {
        @include btn_design($bg_light_gray, $black, 100%, $paddingBtnMob, $fontBtnMobile)
    }

    .btn_light_blue {
        @include btn_design($bg_light_blue, $bg_title_blue, 150px, $paddingBtnMob, $fontBtnMobile);
        border-radius: 5px;
    }
}