.hamburger{
    background-color: #e0e5ec;
    height: 35px ;
    width: 35px;
    border-radius: 5px;
    /* margin-left: 10px; */
    margin-right: 15px;
    padding: 2px;
}

.hamburger svg{
    font-size: 30px;
    color: #6aba2a;
}

.nav_menu{
    background-color:#005ca8;
    width: 230px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 300ms;
    z-index: 1;
}

.nav_menu.active{
    right: 0;
    transition: 350ms;
}

.nav_menu_items{
    list-style: none;
    width: 90%;
}

.nav_bar_toggle{
    margin-left: -41px;
}

.nav_bar_toggle svg{
    font-size: 30px;
    color: #6aba2a;
    background-color: #e0e5ec;
    height: 35px ;
    width: 35px;
    border-radius: 5px;
    padding: 2px;
}

.nav_text{
    margin: 5px 0px;
    /* border: 1px solid white; */
    margin-left: -41px;
    color: white;
    /* background-color: cyan; */
}

.nav_text svg{
    color: #6aba2a;
    margin-right: 10px;
}
.link{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: white;
    /* background-color: aquamarine; */
    padding: 8px 0px;
}
/* .link:hover{
    background-color: aqua;
} */