.coupon_card{
    width: 100%;
    height: 70px;
    border: 1px solid #b1bcc7;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    margin-bottom: 15px;

    .card_left{
        width: 90px;
        height: 100%;
        background-color: rgba(234, 237, 243, 0.83);
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        p{
            color: #000000;
            font-size: 12px;
            font-weight: 600;
            text-align: center;
        }
    }

    .card_right{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        p{
            margin: 0;
            color: #000000;
            font-size: 11px;
            line-height: 11px;
        }
        .code{
            margin-top: 5px;
            display: flex;  
            align-items: center;
            
            p{
                font-size: 13px;
            }
            .button{
                svg{
                    fill: #005ca8;
                    font-size: 15px;
                    margin-left: 7px;
                    cursor: pointer;
                }
            }
        }
    }
}