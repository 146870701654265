@charset "UTF-8";
//font sizes

$margin10: 10px;

$paddingBtn: 10px;
$paddingBtnMob: 8px;
$fontBtnWeb: 12px;
$fontBtnMobile: 14px;

$font14: 14px;
$font12: 12px;
$font10: 10px;
$font16: 16px;
$font35: 35px;
$font22: 22px;
$fontMobile2: 19px;
$fontMobile: 22px;
// Colors
//project color
$antiFlashWhite: #EEF1F3;
$brightGray: #EAEDF3;
$shadowBlue: #7E8FA2;
$checkboxBorder: #929292;
$bg_light_blue: #E0EBF4;
$bg_green: #93C01C;
$bg_white: #ffffff;
$bg_blue: #EEF1F3;
$bg_title_blue: #005CA8;
$bg_light_green: #E6F0CA;
$bg_light_sel_green: #cbe090;
$bg_light_gray: #E1E5E9;
$bg_red: red;
$darkgreen: darkgreen;
$darkGray: gainsboro;
$bg_grey_1: #ebebec;
//======= [MKN] ======
//@import "../../../assets/css/global.scss";

//colors
$just_black: black;
$fill_green: #6aba2a;
$fill_green_two: #54951f;
$fill_green_three: #55b110;
$fill_orange: #ffa101;
$fill_bg_grey: #354052;
$fill_bg_grey_one: #67758d;
$fill_bg_grey_two: #bbc5d5;
$fill_bg_one: #f7f8fa;
$fill_bg_two: #fadeaf;
$fill_bg_three: #ffc1bb;
$fill_bg_four: #b3b8c0;
$fill_bg_five: #eaedf3;
$fill_bg_six: #f35162;
$fill_bg_seven: rgb(158, 158, 158);
$fill_bg_eight: #d5dae1;
$fill_bg_nine: #e69875;
$fill_bg_ten: rgba(230, 152, 117, 0.16);
$fill_bg_ele: #f7f7f7;
$fill_bg_twel: #eee;
$fill_blue: #3794fc;
$fill_purple: #9d60fb;
$fill_grey: #8a919b;
$fill_bg_fourt: #dde2ea;



$fill_border: #e2e7ee;
$fill_border_two: #bbc5d5;
$fill_border_three: #ccd2db;
$fill_border-four: #ddd;
$fill_border-five: #2684ff;
$fill_border_six: #73879f;
$fill_bg_btn: rgba(0, 92, 168, 0.16);

$text_col: #7f8fa4;
$text_col_two: #11141a;
$text_col_three: #322a7d;
$text_col_four: #303238;
$text_col_five: #67758d;
$text_col_grey: grey;

$shadow: rgba(0, 0, 0, 0.06);
$shadow_two: rgba(0, 0, 0, 0.09);

$bg_mob1: #dee3ea;

//font-sizes
$font8: 8px;
$font18: 18px;
$font11: 11px;
$font13: 13px;
$font15: 15px;
$font17: 17px;
$font20: 20px;
$font24: 24px;
$font25: 25px;
$font35: 35px;
$font30: 30px;
$font40: 40px;

//font-weight
$font_weight_100: 100;
$font_weight_200: 200;
$font_weight_300: 300;
$font_weight_400: 400;
$font_weight_500: 500;
$font_weight_600: 600;
$font_weight_700: 700;
$font_weight_800: 800;
$font_weight_900: 900;
$font_weight_1000: 1000;

//font-family
$font1: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


//=============

$white: hsl(0, 100%, 99%);
$black: hsl(0, 0%, 11%);
$error: hsl(350, 100%, 56%);
$success: hsl(166, 100%, 30%);
$info: hsl(218, 100%, 50%);
$bg2: #757a8d;
$primary: hsl(233, 62%, 14%);
$primaryDark: hsl(240, 14%, 12%);
$primaryMedium: hsl(233, 38%, 20%);
$primaryLight: hsl(232, 23%, 32%);
$primaryGreyish: hsl(233, 24%, 22%);
$primaryLightText: hsla(233, 62%, 14%, 0.133);
$primaryInverted: hsl(53, 62%, 86%);

$secondary: hsl(49, 87%, 50%);
$secondaryLight: hsl(51, 100%, 50%);
$secondaryDark: hsl(37, 100%, 50%);

$facebookBlue: #62a7c8;

$accent: hsl(351, 100%, 61%);
$accentLight: hsl(351, 100%, 76%);
$accentMedium: hsl(351, 80%, 61%);
$accentDark: hsl(351, 100%, 48%);
$accentInverted: hsl(171, 100%, 39%);

$grey: hsl(199, 11%, 46%);
$greyDark: hsl(240, 8%, 23%);
$greyMediumDark: hsl(0, 0%, 40%);
$greyMediumLight: hsl(0, 0%, 75%);
$greyLight: hsl(197, 9%, 85%);
$greyBluish: hsl(240, 29%, 89%);
$greyBluishDark: hsl(232, 17%, 56%);
$greyFont: #62727a;

// Utils

$animate: all 0s linear;

$fontBold: 600;
$fontBolder: 800;
$fontBoldest: 900;

//border
$border_1: #8a8a8a;
$border_2: #d6d6db;
$border_3: hsl(0, 0%, 80%);
$border_4: #707070;
$border_5: rgba(248, 248, 249, 0.17);

// Media Queries

$breakpoints: (
  "phone-small": 380px,
  "phone": 480px,
  "phone-wide": 576px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-wide": 1024px,
  "desktop": 1248px,
  "desktop-wide": 1440px,
);

// Mixins

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type ==max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-columns {
  @include flex-center;
  flex-direction: column;
}

@mixin placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin text-shorten($numLines: 1) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: $numLines) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
  }
}

@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;

  @if $orientation =="vertical" {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  }

  @else if $orientation =="horizontal" {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  }

  @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

@mixin center-vertically {
  position: absolute;
  top: 50%;
  left: 50%;
  @include prefix(transform, translate(-50%, -50%), "webkit" "ms");
}

@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);

    @if $i and $i +1 <=length($args) and type-of(nth($args, $i + 1))==number {
      #{$o}: nth($args, $i + 1);
    }
  }

  position: $position;
}

// Positioning helpers
@mixin absolute($args: "") {
  @include position(absolute, $args);
}

@mixin fixed($args: "") {
  @include position(fixed, $args);
}

@mixin relative($args: "") {
  @include position(relative, $args);
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin animation($value) {
  -webkit-animation: $value;
  animation: $value;
}

@mixin animation-delay($value) {
  -webkit-animation-delay: $value;
  animation-delay: $value;
}

@mixin animation-direction($value) {
  -webkit-animation-direction: $value;
  animation-direction: $value;
}

@mixin animation-duration($value) {
  -webkit-animation-duration: $value;
  animation-duration: $value;
}

@mixin animation-fill-mode($value) {
  -webkit-animation-fill-mode: $value;
  animation-fill-mode: $value;
}

@mixin animation-iteration-count($value) {
  -webkit-animation-iteration-count: $value;
  animation-iteration-count: $value;
}

@mixin animation-name($value) {
  -webkit-animation-name: $value;
  animation-name: $value;
}

@mixin animation-play-state($value) {
  -webkit-animation-play-state: $value;
  animation-play-state: $value;
}

@mixin animation-timing-function($value) {
  -webkit-animation-timing-function: $value;
  animation-timing-function: $value;
}

@mixin css3-prefix($prop, $value) {
  -webkit-#{$prop}: #{$value};
  -moz-#{$prop}: #{$value};
  -ms-#{$prop}: #{$value};
  -o-#{$prop}: #{$value};
  #{$prop}: #{$value};
}

@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  box-sizing: $box-model;
}

@mixin light-hover {
  cursor: pointer;
  box-shadow: inset 0 0px 1000px 1000px rgba($white, 0.1), 0 23px 46px 0px rgba($white, 0.1);
  @include css3-prefix(transition, $animate);
}

@mixin light-active {
  cursor: pointer;
  box-shadow: inset 0 0 1000px 1000px rgba($white, 0.2), 0 23px 46px 0px rgba($white, 0.2);
  @include css3-prefix(transition, $animate);
}

@mixin dark-hover {
  cursor: pointer;
  box-shadow: inset 0 0 1000px 1000px rgba($black, 0.05), 0 23px 46px 0px rgba($black, 0.1);
  @include css3-prefix(transition, $animate);
}

@mixin dark-active {
  cursor: pointer;
  box-shadow: inset 0 0 1000px 1000px rgba($black, 0.1), 0 23px 46px 0px rgba($black, 0.2);
  @include css3-prefix(transition, $animate);
}

@mixin button-primary {
  background-color: $primaryMedium;
  border: 2px solid $primaryLight;
  @include css3-prefix(border-radius, 6px);
  color: $white;
  font-size: 16px;
  width: 100%;
  height: 50px;
  @include css3-prefix(transition, $animate);
  @include flex-center;
}

@mixin button-primary-transparent {
  background-color: transparent;
  border: 2px solid $primary;
  @include css3-prefix(border-radius, 6px);
  color: $primary;
  font-size: 16px;
  width: 100%;
  height: 50px;
  @include css3-prefix(transition, $animate);
  @include flex-center;
}

@mixin button-primary-dark {
  background-color: $primary;
  border: 2px solid $primaryMedium;
  @include css3-prefix(border-radius, 6px);
  color: $white;
  font-size: 16px;
  width: 100%;
  height: 50px;
  @include css3-prefix(transition, $animate);
  @include flex-center;
}

@mixin button-secondary {
  background-color: $secondary;
  border: 2px solid $secondary;
  @include css3-prefix(border-radius, 6px);
  color: $primary;
  font-size: 16px;
  width: 100%;
  height: 50px;
  @include css3-prefix(transition, $animate);
  @include flex-center;
}

@mixin button-accent {
  background-color: $accent;
  border: 2px solid $accent;
  @include css3-prefix(border-radius, 6px);
  color: $white;
  font-size: 16px;
  width: 100%;
  height: 50px;
  @include css3-prefix(transition, $animate);
  @include flex-center;
}

.active_tab {
  border-bottom: 3px solid #005ca8;
  padding: 5px 0px 2px 0px;
  background-color: #005ca827 !important;
  color: #005ca8;
}