@import "../../assets/css/global.scss";
@import "../../assets/css/components.scss";

.tableDesign {
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid $bg_light_blue;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    
    tr:hover {
        td {
            /*border-top-color: red;
            border-bottom-color: red;*/
            background-color: $bg_light_blue;
            /* &:first-child {
                border-left-color: red;
            }

            &:last-child {
                border-right-color: red;
            }*/
        }
    }

    thead {
        background-color: $bg_light_gray;
        border: 1px solid $bg_light_gray;
    }

    thead th {
        padding: 12px;
    }

    thead th:first-child {
        text-align: left;
    }

    tbody {
        border: 2px solid $bg_light_gray ;
    }

    tbody tr td {
        padding: 10px;
        font-size: $font12;
    }

    tbody tr td:first-child {
        text-align: left;
        color: $fill_bg_grey;
    }

    tbody tr:not(:last-child) {
        border-bottom: 1px solid $bg_light_gray;
    }

    //========================== AJAY ji =======================

    // thead {
    //     tr {
    //         &:first-child {
    //             background-color: $bg_light_gray;
    //             text-align: left;
    //         }

    //         &:last-child {}
    //     }
    // }

    // th,
    // td {
    //     padding: 6px;
    //     border-bottom: 1px solid $bg_light_gray;

    //     &:last-child {
    //         text-align: right;
    //     }
    // }

    //=========================================================================

}

.pagination{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.count{
    display: flex;
    justify-content: center;
    align-items: center;
}
.count span{
    font-size: $font14;
}