@import "../../assets/css/global.scss";
.card{
    display: flex;
    margin-top: 15px;
}

.logo{
    width: 43px;
    height: 43px;
    background-color: $fill_bg_two;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.logo svg{
    color: $fill_orange;
}
.papers h4{
    margin: 0;
    color: $fill_bg_grey;
    margin-left: 15px;
}
.papers span{
    font-size: $font10;
    color: $text_col;
    margin-left: 15px;
}