@import "../../../assets/css/global.scss";
@import "../../../assets/css/components.scss";

.download_bundle_plan_invoice {
    // display: flex;
    // justify-content: center;
    // margin: 20px 0px;

    button {
        background-color: #005ca8;
        border: none;
        color: white;
        padding: 8px 20px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        margin-left: 5px;
    }
}

@mixin flex_container {
    display: flex;
    flex-direction: row;
}

.text_align {
    border: 1px;
}

.home_logo {
    cursor: pointer;
}

.logo_body {
    width: 100%;

    td {
        &:first-child {
            img {
                width: 40%;
            }

            text-align: left
        }

        &:last-child {
            text-align: right
        }
    }
}

.biller_details_div {
    @include flex_container;
    margin-top: 20px;
    text-transform: capitalize;

    div {
        &:first-child {
            width: 70%;
        }

        &:last-child {
            width: 30%;
        }
    }

    .biller_address {
        color: $black !important;

        .biller_address_details {
            margin-top: 20px;
            width: 100%;
        }
    }
}

.heading {
    color: $bg_title_blue;
    text-align: center;
    font-size: $font25;
}

.biller_details_table {
    border-collapse: collapse;

    tr,
    td {
        padding: 0px;
        margin: 0px;
        padding: 0px 20px 0px 0px;
        vertical-align: top;
        text-align: left;

        * {
            vertical-align: top;
            text-align: left;
        }
    }

    th {
        vertical-align: top;
        text-align: left;

        * {
            vertical-align: top;
            text-align: left;
        }
    }
}



