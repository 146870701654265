@import "../../../assets/css/global.scss";

.waiting_button{
    display: flex;
    justify-content: center;
    // cursor: pointer !important;
}
.waiting_button button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
}
.waiting_button svg{
    color: $fill_orange;
    font-size: $font18;
}
.waiting_button span{
    font-size: $font11;
    font-weight: $font_weight_700;
    color: $fill_bg_grey;
}
