@import "../../assets/css/global.scss";

.button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: $bg_white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    cursor: pointer;

    &:disabled {
        /* Styles for the disabled button */
        opacity: 0.5;
        cursor: not-allowed;
        /* Additional styling... */
    }
}