@import "../../../assets/css/global.scss";
@import "../../../assets/css/components.scss";

@mixin shadow {
    box-shadow: 0 12px 14px $bg_light_gray, 0 13px 13px $bg_white;
}


.invoice_body {
    @include shadow;
    padding: 20px;
    width: 80% !important;
}

.bundle_bill_table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
        padding: 7px;
        vertical-align: top;
        text-align: left;

        p {
            color: $black !important;
        }
    }

    tr {
        &:first-child {
            background-color: $bg_title_blue;
            color: $bg_white;
            text-align: left;
        }

        &:last-child {
            .total_amount {
                background-color: $bg_title_blue;
                color: $bg_white;
            }
        }
    }
}

.term_condition {
    color: $black !important;

    h3 {
        font-weight: normal;
    }
}

.font_bold {
    font-weight: bold;
}