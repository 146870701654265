@import "../../../assets/css/global.scss";
@import "../../../assets/css/components.scss";

.syllabusSelect {
    label {
        color: #fff;
        font-size: 11px;
    }

    .mui_select {
        background-color: #fff;
        font-weight: 500;
        padding-left: 0px;
        border-radius: 4px;
        width: 100%;
        font-size: 12px;
        border-width: 1px;
        height: 30px;
    }

    .mui_select_white {
        background-color: #fff;
    }

    .mui_select_grey {
        background-color: #eee;
    }
}

@media only screen and (max-width: 680px) {
    .syllabusSelect {
        label {
            color: #fff;
            font-size: 16px;
        }

        .mui_select {
            background-color: #fff;
            font-weight: 500;
            padding-left: 0px;
            border-radius: 4px;
            width: 100%;
            font-size: 16px;
            border-width: 1px;
            height: 30px;
        }
    }
}