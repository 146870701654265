.pendingOrder {
    position: fixed;
    top: 165px;
    font-size: 12px;
    left: 10%;
    transform: translateX(-50%);
    // background-color: red;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color: red;
}

.closeButton {
    background: white;
    color: red;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    padding: 5px 10px;
    font-weight: bold;
}