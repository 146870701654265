.view_all_coupons{
    width: 350px;
    height: 100vh;
    background-color: white;

    .all_coupons_container{
        width: 100%;
        height: 100%;
        .all_coupons_header{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #aab6c2;
            padding: 10px 15px;

            p{
                margin: 0;
                font-size: 15px;
                color: #111111;
            }
            svg{
                fill: #000000;
                font-size: 20px;
                cursor: pointer;
            }

        }

        .all_coupons_body{
            width: 100%;
            height: 100%;
            // background-color: rgba(255, 0, 0, 0.234);
            padding: 15px;
            overflow-y: scroll;
            
            padding-bottom: 60px;
            &::-webkit-scrollbar{
                display: none;
            }
        }
    }

}