@import "../../assets/css/global.scss";
@import "../../assets/css/components.scss";

.select_style {
    border: none;
    background-color: $bg_green;
    padding: 10px 20px 10px 20px;
    color: $bg_white;
    width: 170px;
    font-size: $font14;
}



.select_style {
    option {
        border: none;
        background-color: $bg_green;
        padding: 10px 20px 10px 20px;
        color: $bg_white;        
        font-size: $font14;
        border-radius: 0px;
    }
}

@media only screen and (max-width: 680px) {
    .select_style {
        font-weight: bold;
        font-size: $fontBtnMobile;
        width: 100%;
    }
}