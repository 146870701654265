// @import "bootstrap/scss/bootstrap.scss";
@import "../../../assets/css/global.scss";
@import "../../../assets/css/components.scss";

.formGrid {
    @include formGrid;
}

$bg_light_blue: #E0EBF4;
$bg_green: #93C01C;
$bg_white: #ffffff;
$bg_blue: #EEF1F3;
$bg_title_blue: #005CA8;

/***** table design  ****/
@mixin first_column {
    text-align: left !important;
    background-color: $brightGray !important;
    cursor: pointer;
    margin: 0px;
    padding: 10px;
}

@mixin column_design {
    padding: 7px;
    border: $shadowBlue 1px solid;
}

@mixin alternate_column($bg_color, $align) {
    text-align: $align;
    background: $bg_color;
}

.title {
    color: $bg_title_blue;
    font-weight: bold;
}

.category_table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid $fill_border-four;
    font-size: 12px;
    margin-top: 10px;

    th {
        cursor: pointer;
        width: 50px;

        &:nth-child(1) {
            @include first_column;
        }

        @include column_design;

    }

    td {
        &:nth-child(1) {
            @include first_column;
        }

        @include column_design;
    }

    tr {
        td {
            &:nth-child(odd) {
                @include alternate_column($antiFlashWhite, center);
            }

            &:nth-child(even) {
                @include alternate_column($bg_white, center);
            }
        }

        th {
            &:nth-child(odd) {
                @include alternate_column($antiFlashWhite, center);
            }

            &:nth-child(even) {
                @include alternate_column($bg_white, center);
            }
        }
    }
}

@media only screen and (max-width: 680px) {
    .category_table {
        font-size: $font16;
    }
}