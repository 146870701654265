@import "../../../assets/css/global.scss";
@import "../../../assets/css/components.scss";

@mixin flex_container {
    display: flex;
    flex-direction: row;
}

.price {
    color: darkgray;
}

.offprice {
    color: $bg_title_blue;
}

.preferenceOption {
    font-size: $font12;
    font-weight: normal !important;
    border-bottom: 1px $just_black dashed;
    width: 100% !important;
    margin-top: 5px;
}

.gstNumber {
    font-size: 12px;
    line-height: 1;
}

@mixin flex_container_span($first_width, $second_width, $font_size) {
    span {
        font-size: $font_size;

        &:first-child {
            width: $first_width;
            text-align: left;
            padding-right: 5px;
            word-wrap: break-word;
        }

        &:last-child {
            text-align: right;
            width: $second_width;
            /*border:1px solid red;*/
            font-weight: 700;
            word-wrap: break-word;
        }

    }

    @media only screen and (max-width: 680px) {
        span {
            font-size: 15px;

            &:first-child {
                width: $first_width;
                text-align: left;
                padding-right: 5px;
            }

            &:last-child {
                text-align: right;
                width: $second_width;
            }
        }
    }
}

.flex_container {
    @include flex_container;
    @include flex_container_span(80%, 20%, $font10);
}

.total_papers {
    margin-top: 10px;

    .total_body {
        margin-top: 3px;
        @include flex_container;
        @include flex_container_span(60%, 40%, $font10);

    }
}

.cart_container {
    width: 100%;
    background-color: #EAEDF3;
    height: auto;

    .cartHeading {
        background-color: $bg_title_blue;
        color: $bg_white;
        font-size: $font14;
        padding: 10px;
        margin-top: 0px;
        width: 100%;

        i {
            margin-right: 8px;
            cursor: pointer;
        }
    }

    .portion_name {
        font-size: 10px;
        font-weight: 700;
        color: $bg_title_blue;
    }

    .body {
        padding: 0px 10px 10px 10px;
        margin-top: -20px;
        font-weight: bold;

        .dataScroll {
            max-height: 65vh;
            overflow: auto;

            .h6 {
                margin: 0px;
                padding: 0px;
                margin-top: 5px;
            }
        }

        .data {
            margin-top: 10px;
            padding-top: 10px;
            padding-right: 5px;
            font-weight: 500;
            /*border-top: 1px solid darkgray;*/

            .plan_papers {
                font-size: 12px;
                font-weight: 700;
            }

            .plan_subject {
                li {
                    @include flex_container;
                    @include flex_container_span(8%, 0%, $font10);
                    padding: 5px 0px 5px 0px;
                    border-bottom: 1px solid darkgray;
                    margin-top: 3px;
                }

                padding: 0;
                font-size: 10px;
            }

            .plan_type {
                font-size: 12px;

                span {
                    font-size: 10px;
                    font-weight: bold;
                }
            }

            .data_heading {
                @include flex_container;
                @include flex_container_span(8%, 0%, $font12);
                font-size: 13px;
                font-weight: bold;

                .plan_type {
                    font-size: 10px;
                    font-weight: bold !important;
                }

                i {
                    cursor: pointer;
                    font-size: 14px !important;
                    margin-top: 2px;

                    :hover {
                        color: $bg_light_blue !important;
                    }
                }
            }

            .data_body {
                @include flex_container;
                @include flex_container_span(70%, 30%, $font10);
                padding: 10px 0px 10px 0px;
                border-bottom: 1px solid darkgray;
            }

            .data_body_custom {
                @include flex_container;
                @include flex_container_span(8%, 30%, $font10);

                .detailsSpan {
                    width: 62%;
                    line-height: 1.5;
                }

                padding: 5px 0px 5px 0px;
                border-bottom: 1px solid darkgray;
                margin-top: 3px;

                .cartDelete {
                    float: right;
                    margin-top: 0px !important;
                    margin-left: 10px;
                    cursor: pointer;
                    font-size: 14px;
                    //border:1px solid red;
                }
            }

            .data_body_custom_checkout {
                @include flex_container;
                @include flex_container_span(80%, 20%, $font10);

                .priceSpan {
                    width: 20%;
                }

                padding: 5px 0px 5px 0px;
                border-bottom: 1px solid darkgray;
                margin-top: 3px;

                .cartDelete {
                    float: right;
                    margin-top: 0px !important;
                    margin-left: 10px;
                    cursor: pointer;
                    font-size: 14px;
                    //border:1px solid red;
                }
            }
        }

        .cart_offer {
            font-size: $font10;
            color: $darkgreen;
            cursor: pointer;
        }



        .total_price {
            background-color: $bg_white;
            padding: 10px;
            margin-top: 10px;

            .total_body {
                @include flex_container;
                @include flex_container_span(60%, 40%, $font10);

            }
        }

        .checkbox_label {
            font-size: $font12;
            margin-top: 20px;

            input[type='checkbox'] {
                height: 10px;
            }
        }

        .confirm_button_container {
            margin-top: 20px;
        }
    }
}

.cart_container {
    overflow-y: scroll;
    height: 85vh;
    width: 19vw;
}

.cart_container::-webkit-scrollbar {
    display: none;
}



.cartDelete {
    float: right;
    //margin-top: 6px;
    font-size: 14px;
}

.cart_container {
    overflow-y: scroll;
    height: 85vh;
    width: 19vw;
}

.cart_container::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 680px) {

    .cart_container {
        overflow-y: scroll;
        height: 85vh;
        width: 100%;
    }
}

.couponBody {
    margin-top: 10px;

    .total_body {
        display: flex;
        flex-direction: row;

        .cartDelete {
            //margin-top: 2px;
            font-size: 14px;
        }

        .couponValue {
            width: 40%;
            color: $bg_title_blue;
        }

        span {
            font-size: 12px;

            &:first-child {
                width: 50%;
                text-align: left;
                padding-right: 5px;
            }

            &:last-child {
                text-align: right;
                width: 10%;

                i {
                    cursor: pointer;
                }
            }

        }
    }
}

.otherDetails {
    margin-top: 20px;

    .total_body {
        display: flex;
        flex-direction: row;

        span {
            font-size: 12px;

            &:first-child {
                width: 60%;
                text-align: left;
                padding-right: 5px;
            }

            &:last-child {
                text-align: right;
                width: 40%;
                color: $bg_title_blue;
                text-transform: capitalize;
            }

        }
    }
}

.view_coupons {
    p {
        margin: 0;
        font-size: 11px;
        color: $bg_title_blue;
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
    }
}

.btn_apply {
    border: none;
    background-color: $bg_title_blue;
    color: white;
    border-radius: 5px;
    padding: 9px 13px;
    margin-top: 21px;
    outline: none;
    cursor: pointer;
    width: 100%;
}

.term_condition {
    color: $bg_title_blue;
    cursor: pointer;
    font-size: 12px
}