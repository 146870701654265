@import "../../../assets/css/global.scss";

.logout {
    font-size: $font16;
    font-weight: bold;
    color: #005ca8;
    margin-top: 0px;
    cursor: pointer;
    text-decoration: none;

}

.right_bar {
    // width: 32%;
    min-width: 350px;
    max-width: 350px;
    height: 100vh;
    padding: 20px;
    background-color: $bg_white;
}

.rightContent {
    background-color: $bg_white;
    width: 100%;
    margin-top: 0px;
}

.rightContent {
    position: relative;
}

.hide {
    position: absolute;
    top: 14px;
    left: -30px;
}

.hide svg {
    color: $fill_bg_seven;
    box-shadow: 1px 2px 4px $just_black;
    border-radius: 20px;
    padding: 0;
    transition: 200ms;
    cursor: pointer;
}

.hide svg:hover {
    color: $fill_bg_eight;
}

@media only screen and (max-width: 820px) {
    .hide {
        display: none;
    }

    .right_bar {
        width: 100%;
        height: 80vh;
    }

    .logout {
        font-size: 23px;

    }
}