@import "../../assets/css/global.scss";
@import "../../assets/css/components.scss";

.heading_part {
    margin-top: 30px;
    margin-bottom: 30px;
}

.heading_half_part {
    margin-top: 10px;
    margin-bottom: 10px;
}

.h6 {
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0px 0px 0px;
}

.h6_half {
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0px 0px 0px;
}

.h6_half i {
    font-size: 10px;
    margin-right: 5px;
}

.headDescriptionBody {
    margin-left: 27px;
    margin-top: 5px;
    padding-bottom: 20px;
    border-bottom: 1px solid #DBE0E5;
}

.headDescription {
    font-size: 10px;
    width: 75% !important;
}

.h6 i {
    font-size: 13px;
    margin-right: 15px;
    margin-top: -10px;
}

@media only screen and (max-width: 768px) {
    .h6 {
        display: flex;
        font-size: $fontMobile2;
        //margin: 20px 0px 10px 0px;
    }

    .h6 i {
        font-size: $fontMobile2;
        margin-right: 15px;
        margin-top: 5px;
    }

    .headDescription {
        font-size: 13px;
        width: 100% !important;
        text-align: justify;
    }
}