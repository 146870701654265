@import "../../../assets/css/global.scss";

.container {
    background-color: $fill_bg_one;
    padding: 10px 20px;
    width: 100%;
    min-height: 100vh;
    border-right: 1px solid $fill_border;

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background-color: red;

        .logo {
            display: flex;
            align-items: center;

            .logo_main {
                // height: 32px;
                height: 40px;
            }
        }

        .list_item {
            display: flex;
            align-items: center;

            .list {
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // min-width: 310px;
                // max-width: 310px;
                // background-color: red;
                gap: 8px;

                .support,
                .cart {
                    margin-right: 5px;

                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        gap: 4px;

                        span {
                            font-size: $font16;
                            font-weight: $font_weight_500;
                            margin-left: 0px;
                            color: $fill_bg_grey;
                        }

                        svg {
                            color: $fill_bg_grey;
                        }
                    }

                    a:hover {

                        span,
                        svg {
                            color: #93c01c;
                        }
                    }
                }


                .support {

                    a {
                        span {
                            font-size: $font16;
                            font-weight: $font_weight_500;
                        }
                    }
                }

                .bell {
                    margin-right: 5px;
                    position: relative;

                    a {
                        text-decoration: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            svg {
                                color: $fill_bg_grey;
                            }

                            .bell_dot {
                                position: absolute;
                                top: 5px;
                                left: 14px;
                                height: 8px;
                                width: 8px;
                                background-color: $fill_bg_six;
                                border: 1px solid $fill_bg_one;
                                border-radius: 5px;
                            }
                        }

                        span {
                            font-size: $font13;
                            font-weight: $font_weight_500;
                            margin-left: 0px;
                            color: transparent;
                        }
                    }

                    a:hover {
                        svg {
                            color: #93c01c;
                        }
                    }
                }

                .account {
                    cursor: pointer;
                    svg {
                        color: $fill_green;
                        font-size: $font40;
                        transition: 200ms;
                        border-radius: 20px;
                        cursor: pointer;
                    }

                    svg:hover {
                        color: $fill_green_two;
                    }
                }
            }

            .hamburger_menu {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 820px) {
    .account {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .container {
        width: 100%;
        padding: 0px;
        background-color: $bg_white;
        border: none;

        .content {
            border-bottom: 2px solid $fill_border_six;

            .logo {

                .logo_main {
                    height: 40px;
                    margin-top: 12px;
                    margin-left: 12px;
                    margin-bottom: 5px;
                }
            }

            .list_item {
                // background-color: antiquewhite;
                height: 50px;
                padding: 0;
                display: flex;
                align-items: center;

                .list {
                    min-width: 10px;
                    padding: 0;

                    .support,
                    .bell {
                        display: none;
                    }

                    .cart {
                        a {
                            svg {
                                font-size: 32px;
                                margin-top: 5px;
                                margin-right: 20px;
                            }

                            span {
                                display: none;
                            }
                        }
                    }
                }

                .hamburger_menu {
                    display: block;
                }
            }
        }
    }
}