@import "../../../assets/css/global.scss";

.buynow_button{
    display: flex;
    justify-content: center;
}
.buynow_button button{
   
    background-color: $fill_bg_btn;
    border: solid 1px $fill_border;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer !important;
}
.button{
    font-size: $font11;
    color: $bg_title_blue;
    font-weight: $font_weight_600;
}