@import "../../../../assets/css/global.scss";

.subscription {
    border: 1px solid $fill_bg_nine;
    border-radius: 20px;
    padding: 15px;
    background-color: $fill_bg_ten;
    width: 100%;

    .over_flow {
        height: 200px;
        overflow-y: scroll;
        padding-right: 10px;
    }
}

.subscription span {
    color: $text_col_two;
    font-weight: $font_weight_700;
}