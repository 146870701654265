@import "../../assets/css/global.scss";
@import "../../assets/css/components.scss";

// .margin_Top {
//     padding-top: 15px;
// }

// .MuiGrid_root {
//     padding-top: -20px;
// }

// .miui_grid {
//     padding-top: 0px !important;
// }

// .top_content{
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-end;
//     h5{
//         background-color: aqua;
//     }
//     .download_all_button{
//         display: flex;
//         align-items: center;
//         justify-content: flex-end;
//         button{
//             background-color: #005ca8;
//             font-size: 17px;
//             color: white;
//             border: none;
//             padding: 8px 10px;
//             border-radius: 5px;
//         }
//     }
// }


.top_content{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top_content_heading{
        h5{
            // background-color: burlywood;
            margin: 0;
        }
    }

    .top_content_filter{
        div{
            margin-top: -20px;
        }
    }

    .download_all_button{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        button{
            background-color: #005ca8;
            cursor: pointer;
            font-size: 15px;
            color: white;
            border: none;
            padding: 8px 10px;
            border-radius: 5px;
        }
    }
}

@media only screen and (max-width: 768px){
    .top_content{
        padding: 15px;
    }
}

@media only screen and (max-width: 680px){
    .top_content{
        padding: 0px 15px;
        margin-bottom: 15px;
        .top_content_heading{
            h5{
                margin: 0;
            }
        }
        .top_content_filter{
            div{
                margin-top: -10px;
            }
        }
        .download_all_button{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            button{
                background-color: #005ca8;
                font-size: 14px;
                color: white;
                border: none;
                padding: 8px 10px;
                border-radius: 5px;
            }
        }
    }
}