@import "../../../assets/css/global.scss";
@import "../../../assets/css/components.scss";

.checkText {
    font-size: $font14;
    vertical-align: top;
}

.formGrid {
    @include formGrid;
}

.small_checkbox {
    font-size: 12px;
}

.normal_checkbox {
    font-size: 14px;
}

.custom_checkbox_small {
    height: 10px;
    width: 10px;
    margin: 0;
    padding: 0;
    opacity: 1;
    appearance: none;
    border: 1px solid black;
    border-radius: 2px;
    position: relative;
    margin-right: 10px;

    &:checked {
        background-color: $bg_green;
        border: 2px solid $bg_green;

        &:before {
            content: "";
            position: absolute;
            height: 1px;
            background: $bg_white;
            width: 5px;
            top: 4px;
            left: -1.5px;
            transform: rotate(45deg);
        }

        &:after {
            content: "";
            position: absolute;
            height: 1px;
            background: $bg_white;
            width: 8px;
            top: 3px;
            left: 0px;
            transform: rotate(-55deg);
        }
    }

    &:focus {
        outline: none;
    }
}

/* custom checkbox */
.custom_checkbox {
    height: 16px;
    width: 16px;
    margin: 0;
    padding: 0;
    opacity: 1;
    appearance: none;
    border: 2px solid $checkboxBorder;
    border-radius: 2px;
    position: relative;
    margin-right: 10px;

    &:checked {
        background-color: $bg_green;
        border: 2px solid $bg_green;

        &:before {
            content: "";
            position: absolute;
            height: 2px;
            background: $bg_white;
            width: 6px;
            top: 7px;
            left: 1px;
            transform: rotate(44deg);
        }

        &:after {
            content: "";
            position: absolute;
            height: 2px;
            background: $bg_white;
            width: 11px;
            top: 5px;
            left: 3px;
            transform: rotate(-55deg);
        }
    }

    &:focus {
        outline: none;
    }
}