$primary-color: #93C01C; // Change color here. C'mon, try it! 
$text-color: mix(#000, $primary-color, 64%);

.elective_box {
    box-shadow: 0 4px 10px 2px rgba(0, 0, 0, .06);
    padding: 5px;
    border-radius: 5px;
    width: auto;
    display: inline-block;
    position: absolute;
    background-color: #fff;
    margin-left: 10px;
    label {
        display: flex;
        cursor: pointer;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        width: 100%;
        /* Accessible outline */
        /* Remove comment to use */
        /*
            &:focus-within {
                    outline: .125em solid $primary-color;
            }
        */
        input {
            position: absolute;
            left: -9999px;

            &:checked+span {
                background-color: mix(#fff, $primary-color, 84%);

                &:before {
                    box-shadow: inset 0 0 0 0.3375em $primary-color;
                }
            }
        }

        span {
            display: flex;
            align-items: center;
            padding: 2px;
            border-radius: 5px; // or something higher...
            transition: 0.25s ease;
            width: 100%;
            &:hover {
                background-color: mix(#fff, $primary-color, 84%);
            }

            &:before {
                display: flex;
                flex-shrink: 0;
                content: "";
                background-color: #fff;
                width: 1em;
                height: 1em;
                border-radius: 50%;
                margin-right: 0.375em;
                transition: 0.25s ease;
                box-shadow: inset 0 0 0 0.125em $primary-color;
            }
        }
    }
}