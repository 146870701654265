@import "../../../../assets/css/global.scss";

.rank_card {
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    height: 120px;
    background-image: url(../../../../assets/images/Pattern.png);
    background-repeat: no-repeat;
    background-color: $text_col_three;
    background-size: cover;
    margin-top: 15px;
    padding: 28px;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card_content {
    display: flex;
    align-items: center;
}

.logo {
    background-color: $fill_orange;
    color: $fill_orange;
    height: 45px;
    width: 45px;
    border-radius: 15px;
    margin-left: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo svg {
    color: $bg_white;
    font-size: $font35;
}

.letter h4,
.letter p,
.letter .rank {
    color: $bg_white;
}

.letter h4 {
    margin: 0px 0px 0px 15px;
}

.letter p {
    font-size: $font8;
    margin: 0px 0px 0px 15px;
}

.rank {
    font-size: $font30;
    font-weight: $font_weight_600;
    margin-left: 20px;
    margin-top: -10px;
}