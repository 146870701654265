
// .content_table{
//    width: 100%;
//    margin-top: 20px;
//    border-collapse: collapse;
//    border-radius: 10px 10px 0px 0px;
//    overflow: hidden;
// }
// .content_table thead{
//     background-color: #e0e5ec;
//     border: 1px solid #e0e5ec;
// }
// .content_table thead th{
//     padding: 12px;
// }
// .content_table thead th:first-child{
//     text-align: left;
// }
// .content_table tbody{
//     border: 1px solid #e0e5ec;
// }
// .content_table tbody tr td{
//     padding: 10px;
//     font-size: 12px;
//     text-align: center;
// }
// .content_table tbody tr td:first-child{
//     text-align: left;
//     color: #354052;
// }
// .content_table tbody tr:not(:last-child){
//     border-bottom: 1px solid #e0e5ec;
// }
@import "../../assets/css/global.scss";

.qna .desktop th{
    font-size: $font14;
    color: $text_col_two;
    text-align: center;
}

.qna .desktop tr td{
    font-size: $font13;
}
// .qna{
//     .desktop{
//         table{
//             tbody{
//                 tr{
//                     td:not(:first-child){
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                     }
//                 }
//             }
//         }
//     }
// }
.qna .mobile{
    display: none;
}


@media only screen and (max-width: 768px){
    .qna .desktop {
        display: none;
    }
    .qna .mobile {
        display: block;
    }
}