@import "../../assets/css/global.scss";
@import "../../assets/css/components.scss";



.boxStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: #eee;
    box-shadow: 24;
    color: black !important;
    height: 90%;
    overflow: auto;
    padding: 10px;
    overflow: scroll;
}

.boxStyleSmall {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background-color: #fff;
    box-shadow: 24;
    color: black !important;
    height: auto;
    overflow: auto;
    padding: 10px;
    overflow: hidden;
    border-radius: 20px;
    outline: none;
}

.boxStyle p {
    color: black !important;
}

@media only screen and (max-width: 680px) {
    .boxStyleSmall {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
    }
}