.full_tutorial {
    text-align: center;
    border-top: 1px solid #eee;
    margin-top: 10px;

    a {
        text-decoration: none;
    }
}

.carouselContainer {
    .carouselCard {
        text-align: center;

        .cardImageContainer {
            background-color: #E9E9E9;
            border-radius: 20px;

            img {
                width: 90%;
                height: 100%;
            }
        }
    }

    .buttonsCard {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .buttons {
            .next_buttons {
                background-color: #005CA8;
                color: #fff;
                font-weight: 500;
                margin-left: 20px;
                padding: 7px 30px 7px 30px;
            }

            .prev_buttons {
                background-color: #F7F8FA;
                color: #000;
                font-weight: 500;
                padding: 7px 30px 7px 30px;
            }
        }
    }
}

@media only screen and (max-width: 680px) {
    .carouselContainer {
        .carouselCard {
            text-align: center;

            .cardImageContainer {
                background-color: #E9E9E9;
                border-radius: 20px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .buttonsCard {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .buttons {
                .next_buttons {
                    background-color: #005CA8;
                    color: #fff;
                    font-weight: 500;
                    margin-left: 10px;
                    padding: 7px 20px 7px 20px;
                }

                .prev_buttons {
                    background-color: #F7F8FA;
                    color: #000;
                    font-weight: 500;
                    padding: 7px 20px 7px 20px;
                }
            }
        }
    }
}