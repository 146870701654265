@import "./global.scss";

@media print {
    .print {
        display: none;
    }
}

.rupee_symbol {
    font-family: Arial, Helvetica, sans-serif !important;
}

.fa {
    cursor: pointer;
}

.webContent {
    display: block;
}

.w100 {
    width: 100% !important;
}

.mobileContent {
    display: none;
}

.mgTop {
    margin-top: 20px !important;
}

.cursor_pointer {
    cursor: pointer !important;
}

@media only screen and (max-width: 680px) {
    .webContent {
        display: none;
    }

    .mobileContent {
        display: contents;
    }
}

hr {
    background-color: $bg_title_blue;
    height: 2.5px;
}

@mixin checkbox {
    accent-color: $accent;
    width: 18px;
    height: 18px;
    border: 2px solid darken($greyLight, 10%);
}

@mixin checkboxWrapper {
    @include flex-center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    margin: 0 0 15px;

    span {
        font-size: 16px;
        font-weight: 400;
        color: $primary;
    }
}

@mixin title {
    width: 100%;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
}

@mixin hr {
    background-color: black;
    height: 2px;
    width: 100%;
}

//Form grid
@mixin formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 18px;
    column-gap: 30px;
    margin-bottom: 30px;
}