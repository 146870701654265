a.active svg {
    color: #005ca8;
}

a {
    text-decoration: none;
    color: rgb(53, 52, 52);
}

a.active {
    color: #005ca8;
}

a.active li {
    border-right: 3px solid #93c01c;
}

a.active li.extend {
    text-decoration: none;
}
