@import "../../../assets/css/global.scss";



.main_container {
    // background-color: red;
    position: fixed;
    // top: 0;
    inset: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .overlay {
        background-color: rgba(0, 0, 0, 0.253);
        width: 100%;
        height: 100vh;
        position:fixed;
        inset: 0;
        z-index: 150;
    }
}

.filters_container {
    background-color: white;
    border-radius: 6px;
    // margin-top: 18px;
    height: 0;
    width: 0;
    overflow: hidden;
    // position: absolute;
    z-index: 999;
    // width: 350px;
    // height: 250px;
    &.open {
        // right: 300px;
        width: 350px;
        height: 400px;
        transition: height width 0.8s ease;
        border-radius: 6px;
        font-style: "Segoe UI";
    }

    .reason_comment_form {
        padding: 4px 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .txt_area {
        width: 100%;
        padding: 12px 24px;

        textarea {
            width: 100%;
            background-color: #F3F7FF;
            padding: 12px 12px;
            border: 1px solid #BBC5D5;
            border-radius: 4px;
        }
    }

    .prev_comment {
        padding: 0px 24px;
        margin-bottom: 6px;
        font-weight: 700;
    }
}

.filters_container .head_container {
    height: 36px;
    display: flex;
    justify-items: center;
    padding: 0px 12px;
    padding-top: 12px;
    justify-content: space-between;
    font-weight: 600;

    .clear {
        color: #313131;
        cursor: pointer;

        svg {

            font-size: large;
        }

        &:hover {
            background-color: transparent;
        }
    }
}

.filters_container .head_container .head_container_item {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    width: 100%;
    .title {
        color: #313131;
        font-size: 14px;
    }

    svg{
        cursor: pointer;
    }

    svg:hover{
        scale: 1.1;
    }
}



.form_row {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    label{
        font-size: 14px;
        margin-bottom: 4px;
    }
    input, select{
        border: 1px solid #BBC5D5;
        border-radius: 4px;
        height: 32px;
        padding-left: 6px;
    }



    textarea {
        border: 1px solid #BBC5D5;
        border-radius: 4px;
        padding-top: 8px;
        padding-left: 12px;
    }

    .payable_amt{
        background-color: rgb(241, 241, 241);
        padding: 8px;
        border-radius: 6px;
        .row{
            display: flex;
            justify-content: space-between;
            padding: 4px 0px;
        }

        .row:first-child{
            font-weight: bold;
            .amount{
                color: rgb(60, 214, 60);
            }
        }

        hr{
            border-top: 1px solid #BBC5D5;
            height: 1px;
        }
    }
}

